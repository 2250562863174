import DocMgmt from "./pages/DocMgmt";
import UserMgmt from "./pages/UserMgmt";
import Administration from "./pages/Administration";
import Error503 from "./pages/Erros/503";
import Error404 from "./pages/Erros/404";

export const rolesBasic = ['TCS_EMB_USER']

export const rolesMananger = ['TCS_EMB_ADMIN']

export const rolesFullAccess = ['TCS_EMB_SUPPORT']

const routes = [
  {
    path: "",
    title: "Doc Mgmt",
    component: DocMgmt,
    layout: "/admin",
    roles: [...rolesBasic, ...rolesMananger, ...rolesFullAccess]
  },
  {
    path: "/user-mgmt",
    title: "User Mgmt",
    component: UserMgmt,
    layout: "/admin",
    roles: [...rolesMananger, ...rolesFullAccess]
  },
  {
    path: "/administration",
    title: "Administration",
    component: Administration,
    layout: "/admin",
    roles: [...rolesFullAccess]
  },
  {
    path: "*",
    title: "Error 404",
    component: Error404,
    layout: "/admin",
    roles: []
  },
  {
    path: "/503",
    title: "Error 503",
    component: Error503,
    layout: "/error",
    roles: []
  },
  {
    path: "*",
    title: "Error 404",
    component: Error404,
    layout: "/error",
    roles: []
  },
];

export default routes;
