import React, { useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { IoIosTrash, IoMdCloudUpload } from "react-icons/io";
import Swal from "sweetalert2";

import { RowActions } from "./styles";

const FileInput = (props) => {
  const inputFile = useRef(null);
  const [value, setValue] = useState("");

  const openFile = () => {
    inputFile.current.click();
  };

  const fileChange = (input) => {
    if (input.target.files[0].type === "application/pdf")
      setValue(input.target.files[0].name);
    else
      Swal.fire(
        "Atention!",
        "File format is not allowed, please insert a PDF file.",
        "warning"
      );
  };

  const deleteFile = () => {
    setValue("");
    inputFile.current.value = "";
  };

  return (
    <tr>
      <td>{value !== "" ? value : "..."}</td>
      <td>{props.name}</td>
      <td className="actions-style-idiomas">
        <RowActions>
          <Col xs={12} lg={6}>
            <input
              ref={inputFile}
              type="file"
              style={{ display: "none" }}
              onChange={fileChange}
              name="files[]"
            />
            <input type="hidden" value={props.id} name="language[]" />
            <IoMdCloudUpload
              size={24}
              onClick={openFile}
              title="Select File?"
            />
          </Col>
          <Col xs={12} lg={6}>
            <IoIosTrash size={24} onClick={deleteFile} title="Delete?" />
          </Col>
        </RowActions>
      </td>
    </tr>
  );
};

export default FileInput;
