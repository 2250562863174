import React, { useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'

import Authentication from './Authentication'
import LayoutAdmin from './layout/admin'
import LayoutErrors from './layout/erros'
import NotFound from './pages/Erros/404'
import Loading from './components/loading'
import RouteComponent from './components/RouteComponent'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'

const App = () => {
  const user = useSelector((state) => state.dataUser)
  const location = useLocation()

  useEffect(() => {
    if (user) {
      const profile = user.roles.filter((profile) => profile.startsWith('TCS'))
      ReactGA.event('fly_data', {
        login: user.login,
        profile: profile
      })
    }
  }, [location.pathname, user])

  return (
    <Switch>
      <Route path='/authentication/:hash' component={Authentication} />
      <Route path='/error' component={LayoutErrors} />
      <RouteComponent path='/admin' component={LayoutAdmin} />
      <RouteComponent path='/' component={Loading} />
      <Route path='*' component={NotFound} />
    </Switch>
  )
}

export default App
