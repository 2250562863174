import React from 'react'
import { Container, Row } from 'react-bootstrap'

import logoFooter from '../../../assets/images/airplane-logo-footer.png'
import { Footer, ColLogoFooter, ColTextFooter } from './styles'

export default function index() {
  return (
    <Footer>
      <Container>
        <Row>
          <ColLogoFooter xl='9'>
            <img alt='Logot Footer' src={logoFooter} />
          </ColLogoFooter>
          <ColTextFooter xl='3'>
            Copyright 2015 Embraer SA. v1.0.0
          </ColTextFooter>
        </Row>
      </Container>
    </Footer>
  )
}
