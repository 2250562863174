import React from 'react'
import { Switch, Route } from 'react-router-dom'

import routes from '../../routes'

export default function Erros() {
  const renderRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.layout === '/error') {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
            exact
          />
        )
      } else {
        return null
      }
    })
  }

  return (
    <div>
      <Switch>{renderRoutes(routes)}</Switch>
    </div>
  )
}
