import React from 'react'
import { PageError503 } from './styles'
import LogoEmbraer from '../../assets/images/logo.svg'

export default function Error404() {
  return (
    <PageError503>
      <h1>Error 404</h1>
      <p>Page Not Found.</p>
      <img src={LogoEmbraer} alt='Logo Embraer' />
    </PageError503>
  )
}
