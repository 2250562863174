import styled from 'styled-components'

export const TabelaDados = styled.section`
  display: block;
  border: 1px solid rgba(229, 229, 229, 0.54);
  border-radius: 4px;
  margin-top: 16px;

  padding-bottom: 90px;
  margin-bottom: 80px;
  padding: 0px 16px 16px 16px;

  .header-page {
    padding: 16px;
    border-bottom: 1px solid rgba(229, 229, 229, 0.54);

    h2 {
      color: #002b68;
      font-size: 20px;
      font-weight: bold;
      margin: 0px;
    }
  }

  .card-header {
    cursor: pointer;
    display: flex;
    align-items: center;

    h2 {
      font-size: 16px;
      font-weight: bold;
      color: #002b68;
      margin: 0px 10px 0px;
    }
  }

  .card-body {
    padding: 8px;
  }

  .table-bordered thead td,
  .table-bordered thead th,
  .table-bordered td,
  .table-bordered th {
    font-size: 12px;
  }

  .actions-style {
    cursor: pointer;
  }

  .header-systems {
    background-color: #ececec;
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 16px;

    h2 {
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;

      svg {
        margin-left: 12px;
      }
    }

    .body-systems {
      background-color: #fff;
      border-radius: 5px;
    }
  }
`
