import styled from 'styled-components'

export const Header = styled.header`
  width: 100%;
  border-top: 2px solid #0092ed;
  background-image: linear-gradient(
    to right,
    rgba(0, 45, 100, 0.84) 0%,
    #002d64 80%
  );
  background-repeat: repeat-x;
  padding: 15px 0;
`

export const TitleSystem = styled.h1`
  font-size: 20px;
  color: #fff;
  font-weight: bold;
`
export const OpcoesHeader = styled.div`
  display: flex;
  padding-top: 6px;
  a {
    font-size: 12px;
    color: #fff;
    font-weight: normal;
    margin: 0px 5px;
    text-decoration: none;
  }
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 3px;

  span {
    margin-left: 6px;
    color: rgba(255, 255, 255, 0.74);
    font-size: 12px;
  }

  svg {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.74);
  }
`
