import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Table, Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import Lottie from "react-lottie";

import { FiTrash2, FiUnlock, FiLock } from "react-icons/fi";
import { PageAdministrationStyle } from "./styles";
import FormNewSystem from "../../components/form-new-system";
import * as animationData from "../../assets/lottie/117-progress-bar.json";

import config from "../../helpers/config";
import systemService from "../../services/systems";
import administrationService from "../../services/administration";

const Administration = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState([]);

  const loadDataList = () => {
    try {
      systemService.listSystemAdmin().then((response) => {
        setList(response.data);
        setLoading(false);
      });
    } catch (error) {
      Swal.fire("Atention!", error.message, "error");
    }
  };

  const loadTotal = () => {
    try {
      administrationService.totalAdministration().then((response) => {
        setTotal(response.data);
      });
    } catch (error) {
      Swal.fire("Atention!", error.message, "error");
    }
  };

  const confirmDelete = (system) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        try {
          await systemService.deleteSystem(system.code);
          const newList = list.filter((sys) => sys.code !== system.code);
          setList(newList);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } catch (error) {
          const message = error.response.data.message
            ? error.response.data.message
            : error.message;
          Swal.fire("Atention!", message, "error");
        }
      }
    });
  };

  const handleSendForm = async (data) => {
    try {
      const postNewSystem = await systemService.postNewSystem({
        name: data.nameSystem,
        code: data.codeSystem,
        active: true,
        description: data.descriptionSystem,
      });
      const newList = [...list, postNewSystem.data];
      setList(newList);
      Swal.fire("Success!", "System successfully registered.", "success");
    } catch (error) {
      const message = error.response.data
        ? error.response.data.message
        : error.message;
      Swal.fire("Atention!", message, "error");
    }
  };

  const changeStatusSystem = async (status, system) => {
    const sendUpdateSystem = {
      active: status,
    };

    const updateSystem = await systemService.updateSystem(
      sendUpdateSystem,
      system.code
    );

    const newList = list.map((system) => {
      if (system.code === updateSystem.data.code) return updateSystem.data;
      else return system;
    });

    setList(newList);
  };

  useEffect(() => {
    loadDataList();
    loadTotal();
  }, []);

  return (
    <PageAdministrationStyle>
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <h2>Administration</h2>
            </Col>
            <Col className="text-right">
              <Button
                variant="success"
                size="sm"
                onClick={() => setShowModal(true)}
              >
                New System
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <Lottie
              options={config.configLottie(animationData)}
              height={100}
              width={200}
            />
          ) : (
            <Row>
              <Col>
                <Row>
                  <Col xs={12} md={6} lg={6} xl={3}>
                    <h5>
                      Unique Users{" "}
                      <Badge variant="primary">{total.totalUsers}</Badge>
                    </h5>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={3}>
                    <h5>
                      Unique Customers{" "}
                      <Badge variant="info">{total.totalCustomers}</Badge>
                    </h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>System</th>
                          <th>System Code</th>
                          <th>Customers</th>
                          <th>Users</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th style={{ width: "10%" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((system) => {
                          return (
                            <tr key={system.code}>
                              <td>{system.name}</td>
                              <td>{system.code}</td>
                              <td>{system.totalCustomers}</td>
                              <td>{system.totalUsers}</td>
                              <td>{system.description || "..."}</td>
                              <td>{system.active ? "active" : "inactive"}</td>
                              <td align="center" className="btn-remove-system">
                                {system.active ? (
                                  <FiLock
                                    title="Block?"
                                    size={20}
                                    onClick={() =>
                                      changeStatusSystem(!system.active, system)
                                    }
                                    style={{ marginRight: 10 }}
                                  />
                                ) : (
                                  <FiUnlock
                                    title="Release?"
                                    size={20}
                                    onClick={() =>
                                      changeStatusSystem(!system.active, system)
                                    }
                                    style={{ marginRight: 10 }}
                                  />
                                )}

                                <FiTrash2
                                  title="Delete?"
                                  size={20}
                                  onClick={() => confirmDelete(system)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
      <FormNewSystem
        sendForm={(data) => handleSendForm(data)}
        show={showModal}
        onHide={() => setShowModal(false)}
        titleheader="New System"
      />
    </PageAdministrationStyle>
  );
};

export default Administration;
