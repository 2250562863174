import styled from 'styled-components'

export const PageError503 = styled.section`
  width: 100%;
  border-top: 30px solid #002d64;
  text-align: center;

  h1 {
    font-size: 62px;
    font-weight: bold;
    margin-top: 32px;
    color: #629dcb;
  }

  p {
    font-size: 24px;
    color: #757575;
  }

  img {
    width: 280px;
  }
`
