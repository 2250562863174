import React from 'react'
import Lottie from 'react-lottie'

import config from '../../helpers/config'

import * as animationData from '../../assets/lottie/117-progress-bar.json'

const Loading = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Lottie
        options={config.configLottie(animationData)}
        height={100}
        width={200}
      />
    </div>
  )
}

export default Loading
