import React, { useState } from 'react'
import { Modal, Button, Row, Col, Form, Table } from 'react-bootstrap'
import FileInput from '../file-input'
import Swal from 'sweetalert2'

import config from '../../helpers/config'

import './styles.css'

export default function FormNewDocument(props) {
  const {
    titleHeader,
    listSystem,
    sendFormNewDocument,
    listLanguages,
    ...res
  } = props
  const [version, setVersion] = useState('')

  const getLastObject = (array, total) => {
    const first = config.firstObjectArray(array)
    return parseInt(first.version) + 1
  }

  const changeSystemSelect = (input) => {
    if (input.target.value !== '') {
      const getSystem = listSystem.filter(
        (sys) => sys.code === input.target.value
      )

      let getVersion =
        getSystem[0].pdf_version.length > 0
          ? getLastObject(
              getSystem[0].pdf_version,
              getSystem[0].pdf_version.length
            )
          : '0'

      setVersion(getVersion)
    } else {
      setVersion('')
    }
  }

  const sendFormData = (event) => {
    event.preventDefault()
    const formDataSend = document.getElementById('form-new-document')
    const formData = new FormData(formDataSend)

    let fileVerify = []
    listLanguages.forEach((language) => {
      let getInput = document.getElementsByName('files[]')
      getInput.forEach((input) =>
        input.value === '' ? fileVerify.push(input) : null
      )
    })

    if (fileVerify.length > 0) {
      Swal.fire('Atention!', 'All languages ​​are required', 'warning')
    } else {
      sendFormNewDocument(formData)
      setVersion('')
      document.getElementById('form-new-document').reset()
      res.onHide()
    }
  }

  const closeForm = () => {
    setVersion('')
    document.getElementById('form-new-document').reset()
    res.onHide()
  }

  return (
    <Modal
      {...res}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {titleHeader}
        </Modal.Title>
      </Modal.Header>
      <form
        onSubmit={sendFormData}
        id='form-new-document'
        autoComplete='off'
        encType='multipart/form-data'>
        <Modal.Body>
          <Row>
            <Col xs={12} lg={4}>
              <Form.Group controlId='exampleForm.ControlSelect1'>
                <Form.Label>System</Form.Label>
                <Form.Control
                  as='select'
                  onChange={changeSystemSelect}
                  name='system'
                  required>
                  <option value=''>Select System</option>
                  {listSystem.map((system) => (
                    <option key={system.code} value={system.code}>
                      {system.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group controlId='exampleForm.ControlInput1'>
                <Form.Label>DOC #</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='#'
                  name='document'
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Group controlId='exampleForm.ControlInput1'>
                <Form.Label>Version</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  readOnly
                  name='version'
                  defaultValue={version}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId='exampleForm.ControlInput1'>
                <Form.Label>Comments</Form.Label>
                <Form.Control type='text' placeholder='' name='comment' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12} className='checkbox-ponter'>
              <Form.Group controlId='formReaccept'>
                <Form.Check
                  type='checkbox'
                  label='Reaccept'
                  value='1'
                  name='reaccept'
                />
                <small>
                  When selecting Reaccept, all users will sign the document
                  again. If not selected, a new version will be inserted without
                  the need for everyone's signature.
                </small>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Pdf Upload</th>
                    <th>Idioma</th>
                    <th style={{ width: '12%' }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {listLanguages.map((language) => (
                    <FileInput
                      key={language.id}
                      {...language}
                      code={language.code}
                    />
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' onClick={closeForm}>
            Close
          </Button>
          <Button type='submit' variant='success'>
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
