import React from 'react'
import { Media, Row, Col } from 'react-bootstrap'

export default function NoData({ titleMedia, mt }) {
  return (
    <Row className={mt}>
      <Col>
        <Media>
          <Media.Body>
            <h5 className='text-center'>{titleMedia}</h5>
          </Media.Body>
        </Media>
      </Col>
    </Row>
  )
}
