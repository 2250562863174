import api from '../index'

const docmgmt = {
  listSystemDocs: async () => await api.get('systemdocs'),
  listLanguages: async () => await api.get('language'),
  postVersion: async (data) =>
    await api.post('systemdocs', data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }),
  listFilesPdfVersion: async (pdfVersionId) =>
    await api.get(`systemdocs/${pdfVersionId}`),
  downloadFilePdfVersion: async (file) =>
    await api.get(`download?source=${encodeURI(file.source)}`),
  deletePdfVersion: async (pdfVersionId) =>
    await api.delete(`systemdocs/${pdfVersionId}`)
}

export default docmgmt
