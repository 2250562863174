import api from "../index";

const report = {
  getReport: async () =>
    await api.get("report", {
      responseType: 'blob',
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }),
};

export default report;
