import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { IoIosContact } from "react-icons/io";
import { useSelector } from "react-redux";

import { Header, TitleSystem, OpcoesHeader, UserInfo } from "./styles";

import logoEmbraer from "../../../assets/images/logo.svg";

const AdminHeader = () => {
  const userName = useSelector((state) => state.dataUser.name);

  return (
    <Header>
      <Container>
        <Row>
          <Col xl="5">
            <TitleSystem>TCS - Terms & Conditions of Systems</TitleSystem>
          </Col>
          <Col xl="3">
            <OpcoesHeader>
              <Link to="/admin">ADMINISTRATION</Link>
            </OpcoesHeader>
          </Col>
          <Col xl="2">
            <UserInfo>
              <IoIosContact />
              <span>{userName}</span>
            </UserInfo>
          </Col>
          <Col xl="2">
            <img alt="Logo Embraer Header" src={logoEmbraer} />
          </Col>
        </Row>
      </Container>
    </Header>
  );
};

export default AdminHeader;
