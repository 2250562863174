import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Button,
  Card,
  OverlayTrigger,
  Table,
  Popover
} from 'react-bootstrap'
import {
  IoMdInformationCircleOutline,
  IoMdTrash,
  IoIosEye
} from 'react-icons/io'
import Swal from 'sweetalert2'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { rolesBasic } from '../../routes'

import { TabelaDados } from './styles'

import FormNewDocument from '../../components/form-new-document'
import PdfsFiles from '../../components/pdfs-files'

import NoData from '../../components/no-data'
import docmgmtServices from '../../services/docmgmt'

export default function DocMgmt() {
  const [showModal, setShowModal] = useState(false)
  const [showModalFiles, setShowModalFiles] = useState(false)
  const [listSystem, setListSystem] = useState([])
  const [listLanguages, setListLanguages] = useState([])
  const [listFiles, setListFiles] = useState([])

  const user = useSelector((state) => state.dataUser)

  const loadSystemDocs = () => {
    docmgmtServices.listSystemDocs().then((response) => {
      setListSystem(response.data)
    })
  }

  const sendFormNewDocument = async (data) => {
    try {
      await docmgmtServices.postVersion(data)
      loadSystemDocs()
      Swal.fire('Success!', 'Document has been saved successfully.', 'success')
    } catch (err) {
      Swal.fire(
        'Error!',
        'Error saving the document, please try again later.',
        'error'
      )
    }
  }

  const getListLanguages = () => {
    docmgmtServices.listLanguages().then((response) => {
      setListLanguages(response.data)
    })
  }

  const loadFilesVersion = async (pdfVersion) => {
    try {
      setShowModalFiles(true)
      const listFilesPdf = await docmgmtServices.listFilesPdfVersion(
        pdfVersion.id
      )
      setListFiles(listFilesPdf.data)
    } catch (error) {
      Swal.fire('Atention', 'Error listing version files.', 'error')
    }
  }

  const deleteVersion = (pdfVersion) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.value) {
        try {
          await docmgmtServices.deletePdfVersion(pdfVersion.id)
          loadSystemDocs()
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
        } catch (error) {
          Swal.fire('Warning!', error.response.data.message, 'warning')
        }
      }
    })
  }

  useEffect(() => {
    loadSystemDocs()
    getListLanguages()
  }, [])

  return (
    <>
      <TabelaDados>
        <Row>
          <Col>
            <div className='header-page'>
              <Row className='align-items-center'>
                <Col xs={12} lg={6}>
                  <h2>Doc Mgmt</h2>
                </Col>
                {!user.roles.includes(...rolesBasic) ? (
                  <Col xs={12} lg={6} className='text-right'>
                    <Button
                      variant='success'
                      size='sm'
                      onClick={() => setShowModal(true)}>
                      New Document
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </div>
          </Col>
        </Row>
        {listSystem.length > 0 ? (
          <Row>
            <Col>
              <div>
                {listSystem.map((system) => (
                  <div key={system.code}>
                    <div className='header-systems'>
                      <h2>
                        {system.name}
                        {system.description.toString().length > 0 ? (
                          <OverlayTrigger
                            trigger='hover'
                            placement='right'
                            overlay={
                              <Popover id='popover-basic'>
                                <Popover.Title as='h3'>
                                  Description
                                </Popover.Title>
                                <Popover.Content>
                                  {system.description}
                                </Popover.Content>
                              </Popover>
                            }>
                            <IoMdInformationCircleOutline />
                          </OverlayTrigger>
                        ) : null}
                      </h2>

                      <div className='body-systems'>
                        <Card.Body>
                          {system.pdf_version.length > 0 ? (
                            <Table striped bordered hover size='sm'>
                              <thead>
                                <tr>
                                  <th>Doc #</th>
                                  <th>Version</th>
                                  <th>Uploaded Date</th>
                                  <th>Comments</th>
                                  <th
                                    align='center'
                                    style={{
                                      textAlign: 'center',
                                      width: '1%'
                                    }}>
                                    PDF's
                                  </th>

                                  {!user.roles.includes(...rolesBasic) ? (
                                    <th style={{ width: '1%' }}>Actions</th>
                                  ) : null}
                                </tr>
                              </thead>
                              <tbody>
                                {system.pdf_version.map((version) => (
                                  <tr key={version.id}>
                                    <td>{version.document}</td>
                                    <td>{version.version}</td>
                                    <td>
                                      {moment(version.created_at).format(
                                        'MMM Do YYYY HH:mm:ss'
                                      )}
                                    </td>
                                    <td>{version.comment || '...'}</td>
                                    <td align='center'>
                                      <IoIosEye
                                        size={18}
                                        className='actions-style'
                                        title='View PDFs Files?'
                                        onClick={() =>
                                          loadFilesVersion(version)
                                        }
                                      />
                                    </td>
                                    {!user.roles.includes(...rolesBasic) ? (
                                      <td align='center'>
                                        <IoMdTrash
                                          size={18}
                                          className='actions-style'
                                          title='Delete Version?'
                                          onClick={() => deleteVersion(version)}
                                        />
                                      </td>
                                    ) : null}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : (
                            <NoData titleMedia='No Data' mt='mt-3' />
                          )}
                        </Card.Body>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        ) : (
          <NoData titleMedia='No Data' mt='mt-4' />
        )}
      </TabelaDados>
      <FormNewDocument
        show={showModal}
        onHide={() => setShowModal(false)}
        titleHeader='Upload New Document'
        listSystem={listSystem}
        sendFormNewDocument={sendFormNewDocument}
        listLanguages={listLanguages}
      />
      <PdfsFiles
        show={showModalFiles}
        onHide={() => setShowModalFiles(false)}
        titleHeader='Files Version'
        listFiles={listFiles}
      />
    </>
  )
}
