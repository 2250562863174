import React, { useState, useEffect } from 'react'
import { Card, Button, Row, Col, Form } from 'react-bootstrap'
import { FiSearch, FiFile } from 'react-icons/fi'
import ReactDatatable from '@ashvin27/react-datatable'
import Swal from 'sweetalert2'
import Lottie from 'react-lottie'
import downloadFile from 'js-file-download'
import Select from 'react-select'

import config from '../../helpers/config'
import util from '../../helpers/util'
import { PageUserMgmt } from './styles'
import * as animationData from '../../assets/lottie/117-progress-bar.json'

import userMgmtServices from '../../services/usermgmt'
import reportServices from '../../services/report'

const UserMgmt = () => {
  const [usersData, setUsersData] = useState([])
  const [nameSearch, setNameSearch] = useState('')
  const [customerCode, setCustomerCode] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [system, setSystem] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingReport, setLoadingReport] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const options = [
    { value: 'EPY', label: 'ePayments' },
    { value: 'OLP', label: 'Invoices' },
    { value: 'STR', label: 'Store' },
    { value: '', label: 'All' }
  ]

  const loadUsersAccepted = () => {
    userMgmtServices
      .listUserMgmt()
      .then((response) => {
        setUsersData(response.data)
        setLoading(false)
      })
      .catch((error) => {
        const message = error.message
          ? error.message
          : error.response.data.message
        Swal.fire('Atention!', message, 'error')
      })
  }

  const searchByName = (event) => {
    setLoading(true)
    event.preventDefault()
    try {
      let params = []

      if (!!nameSearch) params.push({ param: 'name', value: nameSearch })

      if (!!customerCode) params.push({ param: 'ccode', value: customerCode })

      if (!!customerName) params.push({ param: 'cname', value: customerName })

      if (!!system) params.push({ param: 'system', value: system })

      const getParams = util.arrayToParams(params)

      userMgmtServices.findUserMgmt(getParams).then((response) => {
        setUsersData(response.data)
        setLoading(false)
      })
    } catch (error) {
      const message = error.response.data.message
        ? error.response.data.message
        : error.response.statusText
      Swal.fire('Attention!', message, 'error')
    }
  }

  const handleClickReport = () => {
    setLoadingReport(true)
    reportServices.getReport().then((response) => {
      downloadFile(response.data, `${process.env.REACT_APP_FILENAME_REPORT}`)
      setLoadingReport(false)
    })
  }

  useEffect(() => {
    loadUsersAccepted()
  }, [])

  return (
    <PageUserMgmt>
      <Row className='mt-4'>
        <Col>
          <Card>
            <Card.Header>
              <h2>Search By</h2>
            </Card.Header>
            <Card.Body>
              <form onSubmit={searchByName}>
                <Row>
                  <Col xs={12} lg={3}>
                    <Form.Group>
                      <Form.Label>User Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='User Name'
                        defaultValue={nameSearch}
                        onChange={(input) => setNameSearch(input.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={2}>
                    <Form.Group>
                      <Form.Label>Customer Code</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Code'
                        defaultValue={customerCode}
                        onChange={(input) =>
                          setCustomerCode(input.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={3}>
                    <Form.Group>
                      <Form.Label>Customer Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Name'
                        defaultValue={customerName}
                        onChange={(input) =>
                          setCustomerName(input.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={3}>
                    <Form.Group>
                      <Form.Label>System</Form.Label>
                      <Select
                        options={options}
                        defaultValue={options[3]}
                        onChange={(selected) => setSystem(selected.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={1} className='mt-btn-search'>
                    <Button variant='success' type='submit' block>
                      <FiSearch />
                      Search
                    </Button>
                  </Col>
                  <Col xs={12} lg={1} className='mt-btn-search'>
                    <Button
                      variant='info'
                      type='button'
                      block
                      onClick={handleClickReport}
                      disabled={loadingReport}>
                      <FiFile />
                      {loadingReport ? 'Await' : 'Report'}
                    </Button>
                  </Col>
                </Row>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='mt-4'>
        {loading ? (
          <Lottie options={defaultOptions} height={200} width={200} />
        ) : (
          <Col>
            <ReactDatatable
              columns={config.configDataTableUserMgmt.columnsUserMgmt}
              config={config.configDataTableUserMgmt.config}
              records={usersData}
              tHeadClassName='header-table-search'
              options={{
                page_size: 1
              }}
            />
          </Col>
        )}
      </Row>
    </PageUserMgmt>
  )
}

export default UserMgmt
