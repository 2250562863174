import React from 'react'

import { BsCheckCircle, BsXCircle } from 'react-icons/bs'

const config = {
  configDataTableUserMgmt: {
    columnsUserMgmt: [
      {
        key: 'version',
        text: 'Version',
        className: '',
        align: 'center',
        sortable: true
      },
      {
        key: 'system_name',
        text: 'System',
        className: '',
        align: 'left',
        sortable: false
      },
      {
        key: 'name',
        text: 'User Name',
        className: '',
        align: 'left',
        sortable: true
      },
      {
        key: 'email',
        text: 'User E-mail',
        className: '',
        align: 'left',
        sortable: true
      },
      {
        key: 'customer_code',
        text: 'Customer Code',
        className: '',
        align: 'left',
        sortable: true
      },
      {
        key: 'customer_name',
        text: 'Customer Name',
        className: '',
        align: 'left',
        sortable: true
      },
      {
        key: 'accepted_date_time',
        text: 'Accepted Date',
        className: '',
        align: 'left',
        sortable: true
      },
      {
        key: 'user_ip',
        text: 'User IP',
        className: '',
        align: 'left',
        sortable: false
      },
      {
        key: 'reaccept',
        text: 'Reaccept',
        className: '',
        align: 'left',
        sortable: false,
        cell: (record) => {
          return record.reaccept ? (
            <BsCheckCircle size={24} color="#00b894" />
          ) : (
            <BsXCircle size={24} color="#d63031" />
          )
        }
      }
    ],
    config: {
      page_size: 10,
      length_menu: [10, 20, 50],
      sort: {
        column: 'accepted_date_time',
        order: 'desc'
      }
    }
  },

  configLottie: (animationData) => {
    return {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
  },

  lastObjectArray: (array, total) => {
    return array[total - 1]
  },

  firstObjectArray: (array) => {
    return array[0]
  }
}

export default config
