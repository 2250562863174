import styled from 'styled-components'

export const PageAdministrationStyle = styled.section`
  margin-top: 24px;

  .card .card-header h2 {
    font-size: 20px;
    margin: 4px 0px 0px 0px;
    font-weight: bold;
    color: #002b68;
  }

  .table thead tr th {
    font-size: 14px;
  }

  .btn-remove-system svg {
    cursor: pointer;
  }
`
