const util = {
  arrayToParams: (params) => {
    const arrayParams = params.map((par, idx) => {
      if (idx === 0) return `?${par.param}=${par.value}`
      else return `&${par.param}=${par.value}`
    })

    let string = ''

    arrayParams.forEach((parm) => {
      string += parm
    })

    return string.length > 0 ? string : ''
  }
}

export default util
