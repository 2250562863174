import React, { useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

const FormNewSystem = (props) => {
  const { sendForm, ...rest } = props;

  const [nameSystem, setNameSystem] = useState("");
  const [codeSystem, setCodeSystem] = useState("");
  const [descriptionSystem, setDescriptionSystem] = useState("");

  const handleForm = (event) => {
    event.preventDefault();

    sendForm({ nameSystem, codeSystem, descriptionSystem });

    setNameSystem("");
    setCodeSystem("");
    setDescriptionSystem("");
    props.onHide();
  };

  const closedFormModel = () => {
    setNameSystem("");
    setCodeSystem("");
    setDescriptionSystem("");
    props.onHide();
  };

  return (
    <>
      <Modal
        {...rest}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.titleheader}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={(event) => handleForm(event)} id="formNewSystem">
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>System</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name System"
                    defaultValue={nameSystem}
                    onChange={(input) => setNameSystem(input.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>System Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="System Code"
                    defaultValue={codeSystem}
                    onChange={(input) => setCodeSystem(input.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    defaultValue={descriptionSystem}
                    onChange={(input) =>
                      setDescriptionSystem(input.target.value)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={closedFormModel}>
              Close
            </Button>
            <Button type="submit" variant="success">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default FormNewSystem;
