/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route } from "react-router-dom";

import { useSelector } from "react-redux";
import authentication from "../../services/authentication";
import { setDataUser } from "../../redux/actions/user";

const RouteComponent = (props) => {
  const reduxDispatch = useDispatch();

  const user = useSelector((state) => state.dataUser);

  const [token, _] = useState(sessionStorage.getItem("@tcs-token"));

  useEffect(() => {
    if (!sessionStorage.getItem("@tcs-token") && user.token === "") {
      window.location.href = process.env.REACT_APP_IDENTITY_LOGIN;
    } else {
      if (token) {
        authentication
          .revalidateToken(
            Buffer.from(
              sessionStorage
                .getItem("@tcs-token")
                .toString()
                .replace('"', "")
                .replace('"', ""),
              "utf-8"
            ).toString("base64")
          )
          .then((response) => {
            reduxDispatch(
              setDataUser({
                ...response.data,
                token: sessionStorage.getItem("@tcs-token"),
              })
            );
          });
      }
    }
  }, [reduxDispatch, token, user.token]);

  return <Route {...props} />;
};

export default RouteComponent;
