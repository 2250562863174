import React, { useCallback, useMemo } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import Header from "./header";
import Footer from "./footer";

import routes from "../../routes";

import { ContainerTitlePage, ContainerMenus } from "./styles";

const Admin = () => {
  const location = useLocation();
  const user = useSelector((state) => state.dataUser)

  const verifyAccess = useCallback((user, route) => {
    const roles = user.roles.filter(role => route.roles.includes(role))
    return route.roles.length > 0 ? roles.length > 0 : true
  }, [] ) 

  const renderRoutes = useMemo(() => {
    return routes.map((route, key) => {
      if (route.layout === "/admin" && verifyAccess(user, route)) {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  }, [user, verifyAccess]);

  const renderLinks = useMemo(() => {
    return routes.map((route, key) => {
      const routeLink = route.layout + route.path;
      const classNameSet = routeLink === location.pathname ? "active" : "";
      
      if (route.layout === "/admin" && route.path !== "*" && verifyAccess(user, route)) {
        return (
          <Link
            key={key}
            className={classNameSet}
            to={route.layout + route.path}
          >
            {route.title}
          </Link>
        );
      } else {
        return null;
      }
    });
  }, [location.pathname, user, verifyAccess]);

  return (
    <>
      <Header />
      <ContainerTitlePage fluid>
        <div className="title-system">
          <h5>Terms & Conditions of Systems</h5>
        </div>
      </ContainerTitlePage>
      <ContainerMenus fluid>
        <Row>
          <Col>
            <div className="menus-systems">{renderLinks}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Switch>{renderRoutes}</Switch>
          </Col>
        </Row>
      </ContainerMenus>
      <Footer />
    </>
  );
};

export default Admin;
