import axios from "axios";
import Swal from "sweetalert2";

const api = axios.create({
  baseURL: process.env.REACT_APP_TCS_API,
});

api.interceptors.request.use(
  (config) => {
    const token = JSON.parse(sessionStorage.getItem("@tcs-token")) || null;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response.status === 401) {
      Swal.fire(
        "Atenção!",
        "Permissão negada, faça o login novamente!",
        "error"
      ).then((resp) => {
        if (resp.isConfirmed) {
          window.location.href = process.env.REACT_APP_IDENTITY_LOGIN;
        }
      });
    }
    return Promise.reject(err);
  }
);

export default api;
