export const SET_DATA_USER = "SET_DATA_USER";
export const GET_AUTH = 'GET_AUTH'

export const setDataUser = (dataUser) => ({
  type: SET_DATA_USER,
  roles: dataUser.profile,
  name: dataUser.fullName,
  fullName: dataUser.fullName,
  login: dataUser.login,
  email: dataUser.email,
  token: dataUser.token,
});

export const getAuth = (auth) => ({
  type: GET_AUTH,
  auth
})

export const fetchAuth = (auth) => (dispatch) => dispatch(getAuth(auth))
