import api from '../index'

const systems = {
  listSystemAdmin: async () => await api.get('system'),
  postNewSystem: async (data) => await api.post('system', data),
  deleteSystem: async (code) => await api.delete(`system/${code}`),
  updateSystem: async (data, code) => await api.put(`system/${code}`, data)
}

export default systems
