import styled from 'styled-components'
import { Col } from 'react-bootstrap'

export const Footer = styled.footer`
  background-image: linear-gradient(
    to bottom,
    #000 0%,
    rgba(0, 0, 0, 0.74) 90%
  );
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF000000', endColorstr='#BD000000', GradientType=0);
  height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
`
export const ColLogoFooter = styled(Col)`
  padding-top: 7px;
`

export const ColTextFooter = styled(Col)`
  padding-top: 10px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
`
