import styled from 'styled-components'

export const PageUserMgmt = styled.section`
  .card .card-header h2 {
    font-size: 20px;
    margin: 0px;
    font-weight: bold;
    color: #002b68;
  }

  .form-group .form-label {
    font-size: 13px;
    color: #8a8a8a;
  }

  .mt-btn-search {
    margin-top: 31px;
  }

  .header-table-search {
    tr th {
      color: #002b68;
    }
  }

  .as-react-table {
    font-size: 13px;
  }

  .input-group-text {
    font-size: 12px;
  }

  .input-group {
    select {
      font-size: 12px;
    }
  }
`
