import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import Store from './app/redux/store'

import 'bootstrap/dist/css/bootstrap.min.css'

import App from './app/App'

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
