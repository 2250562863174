import React from 'react'
import { PageError503 } from './styles'
import LogoEmbraer from '../../assets/images/logo.svg'

export default function Error503() {
  return (
    <PageError503>
      <h1>Error 503</h1>
      <p>Service is temporarily unavailable please try again later.</p>
      <img src={LogoEmbraer} alt='Logo Embraer' />
    </PageError503>
  )
}
