import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import authentication from '../services/authentication'
import { setDataUser } from '../redux/actions/user'
import Swal from 'sweetalert2'

const Authentication = () => {
  const history = useHistory()
  const reduxDispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    if (params.hash && params.hash !== '') {
      authentication
        .getAuthentication(params.hash)
        .then((response) => {
          sessionStorage.setItem(
            '@tcs-token',
            JSON.stringify(response.data.tokenEncrypt)
          )
          reduxDispatch(
            setDataUser({
              ...response.data.user,
              token: response.data.tokenEncrypt
            })
          )
          history.push('/admin')
        })
        .catch((err) => {
          Swal.fire('Atenção!', 'Erro ao autenticar o usuário.', 'error').then(
            (resp) => {
              if (resp.isConfirmed) {
                window.location.href = process.env.REACT_APP_IDENTITY_LOGIN
              }
            }
          )
        })
    }
  }, [history, params, reduxDispatch])

  return <div />
}

export default Authentication
