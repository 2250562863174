import styled from 'styled-components'
import { Container } from 'react-bootstrap'

export const ContainerTitlePage = styled(Container)`
  background-color: #f1f2f4;

  .title-system {
    padding: 16px 0px 0px 0px;

    h5 {
      font-size: 12px;
      border-bottom: 2px solid #636b72;
      color: #636b72;
      max-width: 170px;
      padding-bottom: 16px;
      margin: 0px;
    }
  }
`

export const ContainerMenus = styled(Container)`
  margin-top: 16px;
  padding-bottom: 90px;

  .menus-systems {
    display: flex;
    background-color: #fff;

    a {
      padding: 8px 32px;
      border-top: 1px solid #dad9d9;
      border-bottom: 1px solid #dad9d9;
      border-left: 1px solid #dad9d9;
      font-size: 16px;
      text-decoration: none;
      color: #002b68;
    }

    a:hover {
      color: #009898;
    }

    a:first-child {
      border-left: 1px solid #dad9d9;
      border-top: 1px solid #dad9d9;
      border-bottom: 1px solid #dad9d9;
      border-radius: 4px 0px 0px 4px;
    }

    a:last-child {
      border-right: 1px solid #dad9d9;
      border-radius: 0px 4px 4px 0px;
    }

    a.active {
      color: #009898;
      border-bottom: 3px solid #009898;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    }
  }
`
