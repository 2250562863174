import { SET_DATA_USER, GET_AUTH } from "../../actions/user";

const initialState = {
  roles: [],
  name: "",
  fullName: "",
  login: "",
  email: "",
  token: "",
};

export const dataUser = (state = initialState, action) => {
  const actions = {
    [SET_DATA_USER]: {
      roles: action.roles,
      name: action.name,
      fullName: action.fullName,
      login: action.login,
      email: action.email,
      token: action.token,
    },
    [GET_AUTH]: {
      roles: state.roles,
      name: state.name,
      fullName: state.fullName,
      login: state.login,
      email: state.email,
      token: state.token,
    }
  };

  return actions[action.type] || state;
};
